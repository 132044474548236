import React, { useState } from "react";
import { Button, useDataProvider, useNotify, useRedirect, useRefresh } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@material-ui/core";
import { BooleanInput } from "../../utils/components";
import { useRecordContext } from "ra-core";
import SaveIcon from "@material-ui/icons/Save";
import { useFormState } from "react-final-form";
import { LightBlue, LightRed } from "../../utils/components/styles/Colors";
import { Spacer } from "../../utils/components/FormElements";

const stripFollowUpContract = ( formData, createBundleWith = null ) => {
  return {
    ...formData,
    createBundleWithContract: createBundleWith,
  }
}

const StyledSaveButton = ( props ) => {
  const label = props.label ?? props.isCreate ? "Auftrag erstellen" : "Speichern"

  return <Button
    title={label}
    color="primary"
    children={<SaveIcon style={{ fontSize: '18px' }}/>}
    label={label}
    variant="contained"
    style={{ padding: "6px 16px", fontSize: "0.875rem", marginRight: "0.75rem" }}
    {...props}
  />
}

export const CustomSaveButton = ( props ) => {
  const [open, setOpen] = useState( false );
  const [saving, setSaving] = useState( false );
  const { values } = useFormState( { subscription: { values: true } } );
  const refresh = useRefresh()
  const record = useRecordContext()

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const showAdvancedRetrievalWarning = values.vorholung && values.pickupDate === values.deliveryDate
  const driverChanged = values?.hasOwnProperty( 'driver' ) && values?.driver?.userId !== record.driver?.userId
  const showBundleWarning = !props.create && values.isPartOfBundle && "@id" in values && ( values?.isStorniert || driverChanged )
  const showFlexWarning = values.flexContract && values.vorholung && values.daysBetweenPickupAndDelivery <= 1
  const showFlexDriverWarning = values.flexContract && values.driver
  const showFlexSameDayWarning = values.flexContract && values.pickupDate === values.deliveryDate

  const showDialog = showAdvancedRetrievalWarning || showBundleWarning || showFlexWarning || showFlexDriverWarning || showFlexSameDayWarning

  // toolbar save button click
  const onSave = () => {
    if( showDialog ) {
      setOpen( true )
    } else {
      setSaving( true )
      if( props.isCreate ) {
        dataProvider.create( 'contracts', {
          data: {
            ...values,
            createBundleWithContract: props.createBundleWithContract ?? null,
          }
        } ).then( ( response ) => {
          // success side effects go here
          notify( "Auftrag erfolgreich erstellt", { type: "success" } );
          setOpen( false );
          setSaving( false );
          redirect( 'edit', `/contracts`, response.data.id )
        } ).catch( ( error ) => {
          setOpen( false );
          setSaving( false );
          notify( `Fehler beim erstellen: ${error.message}`, { type: "warning" } );
        } )
      } else {
        const strippedFormData = stripFollowUpContract( values )
        dataProvider.update( 'contracts', {
          id: strippedFormData[ '@id' ],
          data: { ...strippedFormData }
        } ).then( ( response ) => {
          // success side effects go here
          notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
          setOpen( false );
          setSaving( false );
          if( props.redirect ) {
            redirect( 'list', '/contracts' )
          } else {
            refresh()
          }
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
          setOpen( false );
          setSaving( false )
        } )
      }
    }
  }

  const onSaveModal = async( formData ) => {
    setSaving( true )
    const strippedFormData = stripFollowUpContract( values )
    if( formData.deactivateBundle ) {
      for( const bundle of strippedFormData.bundles ) {
        await dataProvider.update( 'bundles', {
          id: bundle[ '@id' ],
          data: {
            active: false,
          }
        } ).then( ( response ) => {
          // success side effects go here
          notify( `Fahrtpaket ${bundle.bundle} erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } )
      }
    }

    dataProvider.update( 'contracts', {
      id: strippedFormData[ '@id' ],
      data: {
        ...strippedFormData,
      }
    } ).then( ( response ) => {
      // success side effects go here
      notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      setOpen( false );
      setSaving( false )
      if( props.redirect ) {
        redirect( 'list', '/contracts' )
      } else {
        refresh()
      }
    } ).catch( ( error ) => {
      // failure side effects go here
      notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      setSaving( false )
    } )
  }

  const StyledDialogContentText = ( { bgColor = LightBlue, ...props } ) => (
    <DialogContentText style={{ backgroundColor: bgColor, padding: '10px 15px', borderRadius: '5px', border: '1px solid rgba(0, 0, 0, 0.2)' }} {...props} />
  )

  console.log( props.label )

  return [
    <StyledSaveButton onClick={() => onSave()} disabled={saving} label={props.label ?? (props.isCreate ? 'Auftrag erstellen' : 'Speichern') }/>,
    <Dialog open={open} onClose={() => setOpen( false )}>
      <DialogContent>
        <DialogContentText>
          <Typography variant={"h6"}>Auftrag {record.auftragsnummer} speichern</Typography>
        </DialogContentText>

        {showAdvancedRetrievalWarning && <StyledDialogContentText>
          Dieser Auftrag ist als Vorholung markiert obwohl Abholung und Zustellung am selben Tag stattfinden.
        </StyledDialogContentText>}

        {showBundleWarning && <StyledDialogContentText>
          Dieser Auftrag ist Teil eines Fahrtpaketes. Wenn Sie diesen Auftrag stornieren oder an einen Fahrer vergeben, kann das Fahrtpaket aufgelöst werden:
          <Spacer/>
          <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true}/>
        </StyledDialogContentText>}

        {showFlexSameDayWarning && <StyledDialogContentText>
          Dieser Auftrag ist als Flex-Auftrag markiert, obwohl Abholung und Zustellung am selben Tag statt finden.
        </StyledDialogContentText>}

        {showFlexWarning && <StyledDialogContentText bgColor={LightRed}>
          Flex-Aufträge die auch als Vorholung markiert sind müssen mindestens 2 Arbeitstage auseinander liegen.
        </StyledDialogContentText>}

        {showFlexDriverWarning && <StyledDialogContentText bgColor={LightRed}>
          Disponierte Aufträge können nicht als Flex-Auftrag gespeichert werden.
        </StyledDialogContentText>}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen( false )} color="primary" label={"Abbrechen"}/>
        <StyledSaveButton onClick={() => onSaveModal( values )} disabled={saving || showFlexWarning || showFlexDriverWarning}/>
      </DialogActions>
    </Dialog>
  ]
}
