import React from "react";
import { FormDataConsumer, SelectInput, TextInput, useDelete } from "react-admin";
import { Box, Button, List, ListItem, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { InputContainer, ParagraphBox } from "../../utils/components/FormElements";
import { CustomerReferenceInput } from "../../customers/components/CustomerReferenceInput";
import { useRecordContext } from "ra-core";
import MailIcon from "@material-ui/icons/Mail";

export const UMailFormIterator = (props) => {
  const record = useRecordContext()
  const type = props.type
  const total = props.fields?.value?.filter((item) => item.type === type).length ?? 0;

  const addButtonClickHandler = () => {
    props.fields.push({
                        customer: null,
                        ruleType: type === 'avis' ? 'avis' : '',
                        ruleCondition: '',
                        address: record[ '@id' ],
                        mailAddress: '',
                        type: type,
                      });
  }

  const classes = useStyles();
  let count = 0;

  return (
      <List>
        { props.fields.map( ( item, index ) => {
          if(  type !== props.fields.value[ index ].type ) {
            return null
          }

          ++count

          return <ListItem key={ index } className={ classes.listItem }>
            <ListItemControl index={ count } total={ total } { ...props } style={{paddingLeft: "0.5rem"}}/>

            <FormDataConsumer>
              { ({ formData }) => {

                return props.fields.value[ index ].type === 'mail' ? <MailItem formData={ formData } item={item} index={index} /> : <AvisItem formData={ formData } item={item} index={index} />
              }}
            </FormDataConsumer>

            <Box display={ 'flex' } flexDirection={ "column" } justifyContent={ "center" } ml={ "0.5rem" }>
              <RemoveItemButton index={index} {...props} />
            </Box>
          </ListItem>
        } ) }

        <Toolbar style={ { justifyContent: 'space-between' } }>
          <Button
            color={ 'primary' }
            label="ra.action.add"
            onClick={ addButtonClickHandler }
          >
            <AddIcon />&nbsp;Hinzufügen
          </Button>
        </Toolbar>
      </List>
  )
}

const AvisItem = ( { formData, item, index }) => {
  const classes = useStyles();

  return <ParagraphBox style={ { width: '100%', padding: '0.25rem 0.75rem', alignItems: 'center' } }>
    <InputContainer flex={5} center>
      <CustomerReferenceInput source={`${ item }.customer`} label={"Kunde"} allowEmpty={true} emptyLabel={'Alle Kunden'} fullWidth />
    </InputContainer>

    <InputContainer flex={5} center>
      <TextInput helperText={ false } className={ classes.textInput } label={ "Empfänger E-Mail" } source={ `${ item }.mailAddress` } fullWidth />
    </InputContainer>
  </ParagraphBox>
}

const MailItem = ( { formData, item, index }) => {
  const classes = useStyles();

  return <ParagraphBox style={ { width: '100%', padding: '0.25rem 0.75rem', alignItems: 'center' } }>
    <InputContainer flex={1} left>
      <SelectInput source={`${item}.ruleType`} choices={[
        { id: 'U2', name: 'U2' },
        { id: 'U3', name: 'U3' },
        { id: 'disposition', name: 'Disponiert' },
      ]} label={"Statuswechsel zu"} helperText={false} fullWidth/>
    </InputContainer>

    <InputContainer flex={5} center>
      <CustomerReferenceInput source={`${ item }.customer`} label={"Kunde"} allowEmpty={true} emptyLabel={'Alle Kunden'} fullWidth />
    </InputContainer>

    {formData.addressMails[index].ruleType !== 'disposition' && <InputContainer flex={1} center>
      <SelectInput source={`${item}.ruleCondition`} choices={[
        { id: 'start', name: 'der Start' },
        { id: 'end', name: 'das Ziel' }
      ]} helperText={false} label={"Diese Adresse ist.."} fullWidth/>
    </InputContainer>}

    <InputContainer flex={5} center>
      <TextInput helperText={ false } className={ classes.textInput } label={ "Empfänger E-Mail" } source={ `${ item }.mailAddress` } fullWidth />
    </InputContainer>

    {formData.addressMails[index].ruleType !== 'disposition' && <InputContainer flex={1} right>
      <SelectInput source={`${item}.pdfType`} choices={[
        { id: 'athlonohnebilder', name: 'Ohne Bilder' },
        { id: 'bhgkfz', name: 'Mit KFZ Schein' }
      ]} helperText={false} defaultValue={"athlonohnebilder"} label={"Protokollvariante"} fullWidth/>
    </InputContainer>}
  </ParagraphBox>
}

const RemoveItemButton = (props) => {
  const { index } = props;
  const [ deleteOne ] = useDelete()

  const deleteEntry = () => {
    props.fields.remove( index )
    if( props.fields?.value[ index ][ '@id' ] ) {
      deleteOne(
        'hs_address_mails',
        props.fields?.value[ index ][ '@id' ],
        props.fields?.value[ index ],
        {
          onSuccess: () => {
            notify( `Eintrag erfolgreich entfernt`, { type: 'success' } );
            setLoading( false )
          },
          onFailure: ( error ) => {
            setLoading( false )
            notify( error.message || `Fehler beim entfernen`, { type: 'warning', multiLine: true } )
          }
        } )
    }
  }

  return <Button
    color={ 'primary' }
    title="Position entfernen"
    style={{ minWidth: '32px' }}
    onClick={deleteEntry}>
    <CloseIcon />
  </Button>
}

const ListItemControl = ( props ) => {
  const { index, total } = props;
  const classes = useStyles();
  return <Box className={classes.listItemControl}>

    <Box className={classes.currentPos}>{index}</Box>
  </Box>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    minWidth: '1024px',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    // '&:nth-child(odd)': {
    //   // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
    //   backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    // },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    paddingLeft: "0.5rem"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )
