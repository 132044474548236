import BlockIcon from '@material-ui/icons/Block';
import { Button, useDataProvider, useNotify } from "react-admin";
import { useState } from "react";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";

export const RemoveBundleButton = ( { record, style } ) => {
  const [loading, setLoading] = useState( false );
  const notify = useNotify();
  const dataProvider = useDataProvider();


  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  const removeBundle = async() => {
    setLoading( true );
    dataProvider.update(
      "contracts",
      {
        id: record.id,
        data: {
          avisBundle: null
        }
      }
    ).then( ( response ) => {
      setLoading( false );
      notify( "Paket erfolgreich entfernt", { type: "success" } );
    } ).catch( ( error ) => {
      setLoading( false );
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  };

  return <>
    <Button
      title={"Paket entfernen"}
      children={<BlockIcon/>}
      onClick={() => removeBundle()}
      disabled={loading || !record.avis?.bundle?.id}
      style={buttonStyles}
    />
  </>
}
