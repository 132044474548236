import MailIcon from "@material-ui/icons/Mail";
import { Button, useDataProvider, useNotify } from "react-admin";
import { useState } from "react";

export const AvisMailButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState( false );

  let color = 'grey';
  if( record?.avis?.parkingSpaceNotificationScheduled ) {
    color = 'orange';
    if( record?.avis?.parkingSpaceNotified ) {
      color = 'green';
    }
  }

  const onSave = async() => {
    setLoading( true );

    let data = {
      parkingSpaceNotificationScheduled: !record.avis.parkingSpaceNotificationScheduled,
    }

    if( record.avis.parkingSpaceNotified ) {
      if( record.avis.parkingSpaceNotificationScheduled ) {
        data.parkingSpaceNotificationScheduled = true
      }

      data.parkingSpaceNotified = false
    }
    //
    // console.debug(data)
    // console.debug('avis', record.avis.parkingSpaceNotified)
    // return

    await dataProvider.update(
      "contracts",
      {
        id: record.id,
        data: {
          avis: {
            ...record.avis,
            ...data
          }
        }
      }
    ).then( ( response ) => {
      notify( `Stellplatzinfo ${data.parkingSpaceNotificationScheduled ? '' : 'nicht mehr '}vorgemerkt`, { type: "success" } );
      setLoading( false );
    } ).catch(( error ) => {
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  };



  return <Button
    children={<MailIcon />}
    style={{ color: color }}
    onClick={() => onSave()}
    label={"Mail"}
  />
}
