import { Button, useNotify, useRefresh } from "react-admin";
import React, { useState } from "react";
import { PostRequest } from "../../../utils/request/PostRequest";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";


export const CreateBundleButton = ( { selectedContracts = [], setSelectedContracts, ...props } ) => {
  const [loading, setLoading] = useState( false );
  const notify = useNotify();
  const refresh = useRefresh();

  const createBundle = async() => {
    setLoading( true );
    await PostRequest(
      `${process.env.REACT_APP_API}/api/contract_avis/create_bundle`,
      {
        contracts: Array.from( selectedContracts )
      }
    ).then( ( response ) => {
      setSelectedContracts( new Set() );
      setLoading( false );
      notify( "Paket erfolgreich erstellt", { type: "success" } );
      refresh();
    } ).catch( ( error ) => {
      setLoading( false );
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  };

  return <>
    <Button
      label={"Paket erstellen"}
      children={<AddToPhotosIcon/>}
      onClick={() => createBundle()}
      disabled={loading}
    />
  </>
}
