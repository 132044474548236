export const LightRed = "#FFCCCC";

export const LightGreen = "#CCFFCC";

export const LightBlue = "#CCEEFF";

export const LightPink = "#FFCCFF";

export const LightYellow = "#FFFFCC";

export const LightColorList = [
  { id: LightRed, name: 'Storno' },
  { id: LightYellow, name: 'Adressänderung folgt' },
  { id: LightGreen, name: 'Adressänderung eingetragen' },
  { id: LightBlue, name: 'Rückmeldung Dispo' },
  { id: LightPink, name: 'Erinnerung Office' },
]
