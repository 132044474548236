import * as React from "react";
import { Route } from "react-router-dom";
import ImportComponent from "./routes/import";
import BodyPartsComponent from './routes/bodyparts';
import { ContractBillingList } from "./contracts/billing/ContractBillingList";
import { AthlonImportList, AtrImportList } from "./vwImports/athlon/AthlonIImportList";
import Journal from "./routes/journal";
import Logbook from "./routes/logbook";
import { ContractCreditList } from "./contracts/credit/ContractCreditList";
import Statistics from "./routes/statistics";
import { EntityEdit } from "./routes/Configuration";
import { DispositionList } from "./contracts/disposition/DispositionList";
import { EntityEdit as MultipleContracts } from "./routes/multiple_contracts";
import ProtocolImages from "./routes/protocolImages";
import { AvisList } from "./contracts/avis/AvisList";

const routes = () => {
  return [
    <Route exact path="/import" component={ImportComponent}/>,
    <Route exact path="/athlon_import" component={AthlonImportList}/>,
    <Route exact path="/atr_import" component={AtrImportList} />,
    <Route exact path="/billing_contracts" component={ContractBillingList}/>,
    <Route exact path="/credit_contracts" component={ContractCreditList}/>,
    <Route exact path="/bodyparts" component={BodyPartsComponent}/>,
    <Route exact path="/journal" component={Journal}/>,
    <Route exact path="/logbook" component={Logbook}/>,
    <Route exact path="/statistics" component={Statistics}/>,
    <Route exact path="/configuration" component={EntityEdit}/>,
    <Route exact path="/disposition" component={DispositionList}/>,
    <Route exact path="/contracts/create-multiple" component={MultipleContracts}/>,
    <Route exact path="/protocolImages" component={ProtocolImages}/>,
    <Route exact path="/avis_contracts" component={AvisList}/>,
  ];
};

export default routes;
