import React from "react";
import { DateField as ReactDateField } from "react-admin";

// options will be passed to JS Intl.DateTimeFormat()
const DateField = ( { displayTime, displaySeconds, ...props }) => {
  let options = { year: "numeric", month: "2-digit", day: "2-digit" }
  if( displayTime ) {
    options = { ...options, hour: "2-digit", minute: "2-digit" }
  }

  if( displaySeconds ) {
    options = { ...options, second: "2-digit" }
  }

  return <ReactDateField
    locales="de-DE"
    options={options}
    {...props}
  />
}

export default DateField;
