const translateDriverLabel = (record, emptyLabel = "Kein Fahrer") => {
  if (!record) {
    return null;
  }
  let name = "";
  if( record.kuerzel ) {
    name += record.kuerzel + " - "
  }

  if( record.name ) {
    name += record.name
  }

  if (name !== "" && record.fahrervon && record.fahrervon.kuerzel) {
    name += " | " + record.fahrervon.kuerzel;
  }

  if (name === "") {
    name = emptyLabel;
  }

  return name;
};

export default translateDriverLabel;
