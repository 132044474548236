import axios from "axios";

export const apiEndpoint = process.env.REACT_APP_API;

export const PostRequest = async( requestUrl, postData = {} ) => {
  const { data } = await axios( {
                                  method: "post",
                                  url: requestUrl,
                                  data: postData,
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem( "token" )}`,
                                  },
                                } );

  return data;
};


export const getPDFLink = async( id, pdfTemplate = '' ) => {
  if( !id ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/api/billings/${id}/pdf`;

  return await PostRequest( requestURL, { template: pdfTemplate } );
};

export const generateInvoicePDF = async( id  ) => {
  if( !id ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/invoice/${id}`;

  return await PostRequest( requestURL );
};

export const generatePDFLink = async( url, additionalData = {}  ) => {
  if( !url ) {
    return null;
  }

  return await PostRequest( url, additionalData );
};

export const getGoogleDirections = async (pickupLocation, deliveryLocation, serviceAddresses) => {
  return await PostRequest(`${apiEndpoint}/api/direction`, {
    startLocation: `${pickupLocation.strasse}, ${pickupLocation.ort}`,
    // startLocation: `${pickupLocation.street} ${pickupLocation.nr}, ${pickupLocation.zip} ${pickupLocation.city}`,
    // endLocation: `${deliveryLocation.street} ${deliveryLocation.nr}, ${deliveryLocation.zip} ${deliveryLocation.city}`,
    endLocation: `${deliveryLocation.strasse}, ${deliveryLocation.ort}`,
    waypoints: serviceAddresses?.map((serviceAddress) => {
      return `${serviceAddress.street} ${serviceAddress.houseNumber}, ${serviceAddress.zipCode} ${serviceAddress.city}`;
    })
  });
};

