import { ArrayInput, SearchInput, SelectInput } from "react-admin";
import React from "react";
import SelectArrayInput from "../../../utils/components/SelectArrayInput";

export const avisFilter = [
  <SearchInput
    placeholder="Auftrag, FIN, Fahrer"
    source="specialFilter"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,


  <SelectArrayInput label="Status"
               source="avis_status"
               choices={[
                 { id: 'pending', name: 'Offen' },
                 { id: 'first_contact', name: 'Erstkontakt' },
                 { id: 'awaiting_response', name: 'Rückmeldung ASP abwarten' },
                 { id: 'doing', name: 'In Bearbeitung' },
                 { id: 'confirmed', name: 'Termin bestätigt' },
               ]}
               emptyText={"Alle anzeigen"}
               alwaysOn />,

  <SelectInput label="Auftragstyp"
               source="contractType"
               choices={[
                 { name: 'Abholung', id: 'PICKUP' },
                 { name: 'Zustellung', id: 'DELIVERY' },
               ]}
               emptyText={"Alle anzeigen"}
               alwaysOn />

]

export const avisFilterDefaultValues = {
  "avis_status": ['pending', 'first_contact', 'awaiting_response', 'awaiting_dispo', 'doing']
}
