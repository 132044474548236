import NotesIcon from '@material-ui/icons/Notes';
import { Button, FormWithRedirect, SaveButton, SaveContextProvider, SelectInput, TextField, useDataProvider, useNotify } from "react-admin";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import IconCancel from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import TextInput from "../../../utils/components/TextInput";
import { PostRequest } from "../../../utils/request/PostRequest";
import Typography from "@material-ui/core/Typography";
import { Spacer } from "../../../utils/components/FormElements";
import Grid from "@material-ui/core/Grid";
import IconChargingStation from "../../../utils/components/icons/IconChargingStation";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DateField from "../../../utils/components/DateField";
import Table from "@material-ui/core/Table";
import { translateStatus } from "../AvisList";
import BooleanInput from "../../../utils/components/BooleanInput";
import { SendMailToContactButton } from "./SendMailToContactButton";
import { AvisMailButton } from "./AvisMailButton";

export const AddNotesButton = ( props ) => {
  const { record, style, label } = props;
  const [loading, setLoading] = useState( false );
  const [showDialog, setShowDialog] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if( !record ) {
    return null;
  }

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  const onSave = async( values ) => {
    // console.log( values.avis );
    // return;
    setLoading( true );

    await PostRequest(
      `${process.env.REACT_APP_API}${record.id}/avis_note`,
      {
        note: values.avis.note,
        status: values.avis.status,
        additionalNote: values.avis.additionalNote,
        coordination: values.avis.coordination,
        parkingSpace: values.avis.parkingSpace,
      }
    ).then( ( response ) => {
      notify( 'Erfolgreich gespeichert', { type: 'success' } );
      dataProvider.getOne(
        "contracts",
        { id: record.id, }
      ).then( () => {
        setLoading( false )
      } )
      setShowDialog( false );
    } ).catch( ( error ) => {
      notify( `Error: ${error.response.data[ "hydra:description" ]}`, { type: "warning" } );
      setLoading( false )
    } )
  }

  const gridSpacing = 2;
  const modalSize = record.avis?.linkedAvis ? "lg" : "md";
  const dataGridWidth = record.avis?.linkedAvis ? 3 : 4;

  return <>
    {loading && <GlobalLoading/>}
    <FormWithRedirect
      resource="contracts"
      save={onSave}
      {...props}
      render={( { handleSubmitWithRedirect, pristine } ) => {
        return <SaveContextProvider {...props}>
          <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={modalSize}>
            <DialogTitle>Avisierung für Auftrag {record.auftragsnummer}{record.avis?.linkedAvis?.contractDetails ? ` / ${record.avis.linkedAvis.contractDetails?.orderNumber}` : ''}</DialogTitle>

            <DialogContent style={{ overflow: 'auto' }}>
              <Grid container spacing={gridSpacing}>
                <AddressGridContainer
                  title={"Abholadresse"}
                  type="pickup"
                  record={record}
                  xs={dataGridWidth}
                />

                <AddressGridContainer
                  title={record.avis?.linkedAvis ? "Tauschadresse" : "Zustelladresse"}
                  type="delivery"
                  record={record}
                  xs={dataGridWidth}
                />

                <AddressGridContainer
                  title={"Zustelladresse"}
                  type="delivery"
                  record={record.avis?.linkedAvis?.contractDetails}
                  xs={dataGridWidth}
                />

                <Grid item xs={dataGridWidth}>
                  <Typography variant={"subtitle1"} style={{ fontWeight: 'bold' }}>Fahrzeugdaten</Typography>
                  {record.avis?.linkedAvis && <Typography variant={"subtitle2"} style={{ fontWeight: 'bold' }}>Hinfahrt</Typography>}
                  <Typography variant={"body2"}>
                    {record.carModel}
                    <Box display={"flex"} alignItems={"center"}>
                      {record.verzoegerteAbgabe && <>
                        <IconChargingStation style={{ width: "12px" }}/>&nbsp;
                      </>}
                      {record.licensePlate}
                    </Box>
                    {record.carFin}
                  </Typography>

                  {record.avis?.linkedAvis && <>
                    <Typography variant={"subtitle2"} style={{ fontWeight: 'bold', marginTop: '0.5rem' }}>Rückfahrt</Typography>
                    <Typography variant={"body2"} style={{ overflow: 'hidden', lineBreak: 'anywhere' }}>
                      {record.avis?.linkedAvis?.contractDetails?.carModel}
                      <Box display={"flex"} alignItems={"center"}>
                        {record.avis?.linkedAvis?.contractDetails?.electric && <>
                          <IconChargingStation style={{ width: "12px" }}/>&nbsp;
                        </>}
                        {record.avis?.linkedAvis?.contractDetails?.licensePlate}
                      </Box>
                      {record.avis?.linkedAvis?.contractDetails?.fin}
                    </Typography>
                  </>}
                </Grid>

                <Grid item xs={4}>
                  <SelectInput
                    label={"Status"}
                    source={"avis.status"}
                    choices={[
                      { id: 'pending', name: 'Offen' },
                      { id: 'first_contact', name: 'Erstkontakt' },
                      { id: 'awaiting_response', name: 'Rückmeldung ASP abwarten' },
                      { id: 'doing', name: 'In Bearbeitung' },
                      { id: 'confirmed', name: 'Termin bestätigt' },
                    ]}

                    defaultValue={'pending'}
                    helperText={false}
                    record={record}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectInput
                    label={"Abstimmung"}
                    source={"avis.coordination"}
                    choices={[
                      { id: 'pickup', name: 'Abholung' },
                      { id: 'delivery', name: 'Zustellung' },
                      { id: 'swap', name: 'Tausch' },
                      { id: 'pickup_swap', name: 'Abholung & Tausch' },
                    ]}

                    helperText={false}
                    record={record}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <BooleanInput source={"avis.parkingSpace"} label={"Stellplatz Avisierung"} record={record}/>
                </Grid>

                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <AvisMailButton record={record}/>
                </Grid>

                <Grid item xs={6}>
                  <TextInput multiline={true} source={"avis.additionalNote"} label={"Weitere Notiz hinzufügen"} rows={3} record={record} fullWidth/>
                </Grid>

                <Grid item xs={6}>
                  <TextInput multiline={true} source={"avis.note"} label={"Angepinnte Notiz"} rows={3} record={record} fullWidth/>
                  <BooleanInput label={"Wichtige Notiz"} source={"avis.importantNote"} record={record}/>
                </Grid>

                <AvisHistoryGridItem record={record.avis}/>
              </Grid>
            </DialogContent>

            <DialogActions>
              <SendMailToContactButton record={record} />
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={loading}
              />
            </DialogActions>
          </Dialog>

          <Button
            style={buttonStyles}
            title={"Notiz hinzufügen"}
            onClick={() => setShowDialog( true )}
            children={<NotesIcon/>}
            label={label ?? ''}
          />
        </SaveContextProvider>
      }}/>
  </>
}

const AddressGridContainer = ( { title, type, xs, record } ) => {
  if( !record || !record[ `${type}Location` ] ) {
    return null;
  }

  const address = record[ `${type}Location` ];

  return <Grid item xs={xs}>
    <Typography variant={"subtitle1"} style={{ fontWeight: 'bold' }}>{title}</Typography>
    <Typography variant={"body2"}>
      {address.strasse}<br/>
      {address.ort}

      <Spacer space="0.5rem"/>

      {record[ `${type}Date` ]}<br/>
      {record[ `${type}Time` ]}

      <Spacer space="0.5rem"/>

      {address.ansprechparter1 && <>
        {address.ansprechparter1}<br/>
      </>}

      {address.anspr1telefon && <>
        {address.anspr1telefon}<br/>
      </>}

      {address.anspr1email && <p style={{ whiteSpace: 'break-spaces' }}>
        {address.anspr1email.split( ';' ).join( '\n' )}
      </p>}
    </Typography>
  </Grid>
}

const StyledCell = ( {styles, ...rest} ) => (
  <TableCell {...rest} style={{
    padding: "5px 4px",
    verticalAlign: 'top',
    ...styles
  }}/>
)

const AvisHistoryGridItem = ( { record } ) => {
  if( !record?.notes ) {
    return null;
  }

  const history = record?.notes.sort( ( a, b ) => {
    return new Date( a.date.date ) > new Date( b.date.date ) ? -1 : 1;
  } );

  return <Grid item xs={12}>
    <Typography variant={"h6"}>Änderungsverlauf</Typography>

    <Table>
      <TableHead>
        <TableRow>
          <StyledCell width={"10px"}>#</StyledCell>
          <StyledCell width={"135px"}>Datum</StyledCell>
          <StyledCell width={"50px"}>Nutzer</StyledCell>
          <StyledCell>Notiz</StyledCell>
        </TableRow>
      </TableHead>

      {history?.map( ( log, index ) => {
        let styles = {};
        if( log.user.system ) {
          styles = { color: '#878787' }
        }

        return <TableRow>
          <StyledCell styles={styles}>{history.length - index}</StyledCell>
          <StyledCell styles={styles}><DateField record={log} source={"date.date"} displayTime={true} displaySeconds={true}/></StyledCell>
          <StyledCell styles={styles}><TextField record={log} source={"user.kuerzel"}/></StyledCell>
          <StyledCell styles={styles}>
            <Typography style={{ whiteSpace: 'pre-wrap' }} variant={"body2"} dangerouslySetInnerHTML={{ __html: log.note }} />

            {( log.status?.from || log.status?.to ) && <Typography variant={"body2"}>
              <strong>Statusänderung</strong> {translateStatus( log.status?.from )} &gt; {translateStatus( log.status?.to )}<br/>
            </Typography>}
          </StyledCell>
        </TableRow>
      } )}
    </Table>

  </Grid>
}
